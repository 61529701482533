<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Daftar Partner
    </div>
    <BTabs
      v-model="tabIndex"
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
    >
      <!-- temporarily hide, waiting for talent pool -->
      <BTab
        :active="$route.query.tab === 'talent-pool'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Talent Pool` }}
        </template>
        <TalentPool />
      </BTab>
      <BTab
        :active="$route.query.tab === 'registered'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Registered (${dataTotal.total_registered || 0})` }}
        </template>
        <Registered />
      </BTab>
      <BTab
        :active="$route.query.tab === 'active'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Active (${dataTotal.total_active || 0})` }}
        </template>
        <Active />
      </BTab>
      <BTab
        :active="$route.query.tab === 'pause'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Pause (${dataTotal.total_pause || 0})` }}
        </template>
        <Pause />
      </BTab>
      <BTab
        :active="$route.query.tab === 'off'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          {{ `Off (${dataTotal.total_off || 0})` }}
        </template>
        <Off />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import Registered from './status/Registered.vue'
import Active from './status/Active.vue'
import Pause from './status/Pause.vue'
import Off from './status/Off.vue'
import TalentPool from './status/Talent-pool.vue'

export default {
  components: {
    Registered,
    Active,
    Pause,
    Off,
    TalentPool,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['talent-pool', 'registered', 'active', 'pause', 'off'],
      dataTotal: {},
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      this.$router.replace({ query: { tab } }).catch(err => {})
    },
  },
  mounted() {
    this.getTotal()

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })

    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    const tab = this.tabs[0]
    this.$router.replace({ query: { tab } }).catch(err => {})
  },
  methods: {
    getTotal() {
      const url = 'v1/partners/total'
      komtimAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          this.$toast_error({ message: 'get data product option failed' })
        })
    },
  },
}
</script>
